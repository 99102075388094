<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12">
        <div class="tgju-widget light">
            <div class="list-widget widget-head-page list-row bold list-row-title">
                <div class="list-col l-title head-color">
                    <span class="with-icon"><i class="uil uil-chart"></i>سبد دارایی</span>
                </div>
                <div class="list-col l-item pointer hover head-color  ">
                    <router-link :to="{ name: 'Investment' }" class="l-item-btn-color-blue">
                        نمای‌کلی
                    </router-link>
                </div>
                <div class="list-col l-item pointer hover head-color  ">
                    <router-link :to="{ name: 'InvestmentInventory' }" class="l-item-btn-color-blue">
                        دارایی‌ها
                    </router-link>
                </div>
                <div class="list-col l-item pointer hover head-color  ">
                    <router-link :to="{ name: 'InvestmentBuyHistory' }" class="l-item-btn-color-blue">
                        سوابق خرید
                    </router-link>
                </div>
                <div class="list-col l-item pointer hover head-color  ">
                    <router-link :to="{ name: 'InvestmentSellHistory' }" class="l-item-btn-color-blue">
                        سوابق فروش
                    </router-link>
                </div>
                <div class="list-col l-item pointer hover head-color  ">
                    <router-link :to="{ name: 'InvestmentWatch' }" class="l-item-btn-color-blue">
                        نرخ ها
                    </router-link>
                </div>
                <div class="list-col l-title">
                </div>
                <div class="list-col l-item pointer hover head-color">
                    <a href="" class="l-item-btn-color-blue">
                        <div class="fa fa-question-circle l-item-btn-pages"></div> راهنما
                    </a>
                </div>
            </div>
            <div class="tgju-widget-content full-padding overflow-hidden">
                <WatchComponent :widget="widget" :data="data" :editable="true"></WatchComponent>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// این ویو برای صفحه مشاهده نرخ ها در سرمایه گذاری استفاده می شود
// وضعیت کنونی : غیرفعال
import WatchComponent from '@/components/WatchList.vue'

export default {
    name: 'Watch',
    components: {
        WatchComponent
    },
    data: function () {
        return {
            widget: {},
            data: {},
        }
    },
    mounted() {
        this.$helpers.getWidgets({ widget_type: 'watchlist', store: false }).then((widgets) => {
            let widget = widgets[0];

            this.widget = widget.widget;
            this.data = widget.data;
        });
    },
}
</script>
